.import-batches-screen .react-select > div {
    padding: .64rem;
    color: black;
}

.import-batches-screen .btn-upload {
    padding: 1rem;
}

.import-batches-screen .date-control {
    padding: 1.85rem;
}