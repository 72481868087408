.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.media-expense-wrapper .select-search-container {
  width: 100%;
}

* {
  font-size: .925rem;
}

.navbar .submenu {
	gap: .625rem;
}

.navbar .submenu .btn {
	border-radius: .25rem;
	font-size: .875rem;
	font-weight: 600;
	padding: 1rem 2rem;
}

.navbar .submenu .dropdown {
	position: relative;
}

.navbar .submenu .dropdown .btn {
	margin: 0;
}

.navbar .submenu .dropdown .btn-outline-orange.active {
  background-color: #E36F1E;
  color: #fff;
}

.navbar .submenu .dropdown ul {
	background-color: #fff;
	box-shadow: 0 .625rem .875rem 0 rgba(0 0 0 / 5%);
	border-radius: .25rem;
	inset: calc(100% + .0625rem) 0 auto;
	list-style: none;
	opacity: 0;
	padding: 1.5rem;
	position: absolute;
	transition: .15s all;
	visibility: hidden;
	z-index: 99;
	width: 14.2rem;
}

.navbar .submenu .dropdown:hover ul {
	opacity: 1;
	visibility: visible;
	transition: .15s all;
}

.navbar .submenu .dropdown ul li:not(:last-child) {
	border-bottom: .0625rem solid #DEE2E6;
	margin-bottom: .625rem;
	padding-bottom: .625rem;
}

.navbar .submenu .dropdown ul .nav-link {
	color: #000;
	font-size: .875rem;
	padding: 0;
}

.navbar .submenu .dropdown ul .nav-link:hover {
	text-decoration: underline;
}

.sidebar .sidebar-brand img {
	width: 2.5rem;
}