@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html body {
  font-family: 'Open Sans', sans-serif;
  color: #3F3F3F;
}

html body .header,
.rdt_TableCol {
  color: black;
  font-weight: 600;
  color: #141719;
}

.header,
.rdt_TableCol div {
  font-size: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root, .ui-wrapper {
  height: 100%;
}

html .custom-checkbox .custom-control-label::before {
  height: 1.3rem;
  width: 1.3rem;
  border-radius: 0;
}

html .custom-control-label::after {
  height: 1.8rem;
  width: 1.8rem;
}

html .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #6C757D;
  background-color: #6C757D;
}

html .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  left: -1.8rem;
  top: 0rem;
}

html .custom-control-label {
  padding-left: 5px;
}

.navbar-nav.sidebar {
  background-color: #E36F1E;
  background-image: none;
}

html body .btn,
.topbar .submenu .nav-item .btn.nav-link {
  border-radius: 0.25rem;
  padding: 0.6rem 1rem;
  font-size: 1rem;
}

html body .btn:hover {
  color: #000;
}

.btn.btn-header {
  border-radius: 0.25rem;
  font-size: .875rem;
  font-weight: 600;
  padding: 1rem 2rem;
}

.btn.btn-primary,
.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:not(:disabled):not(.disabled):active,
.btn.btn-orange,
.btn.btn-orange:hover,
html > body .btn.btn-orange:not(:disabled):active {
  background-color: #E36F1E;
  color: white;
  border: 1px solid transparent;
}

.btn.btn-primary:focus,
.btn.btn-orange:focus,
.btn.btn-orange:hover,
html .btn.btn-primary:not(:disabled):not(.disabled):active {
  box-shadow: 0 0 0 0.1rem #E36F1E;
}

.btn.btn-orange:hover {
  color: white;
}

.btn.btn-secondary,
.btn.btn-secondary:active,
.btn.btn-secondary:hover,
.btn.btn-secondary:not(:disabled):not(.disabled):active,
.btn.btn-outline-orange,
.btn-outline-orange:hover {
  border: 1px solid #E36F1E;
  background-color: white;
  color: black;
}

.btn.btn-outline-orange:focus,
.btn.btn-outline-orange:hover {
  box-shadow: 0 0 0 0.1rem #E36F1E;
}

html .btn.btn-secondary:not(:disabled):not(.disabled):active,
html .btn-secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.1rem #E36F1E;
}

body h4 {
  font-size: 2rem;
}

.rdt_TableHeadRow .rdt_TableCol {
  border-top: 3px solid #DEE2E6;
  padding-top: 15px;
  padding-bottom: 15px;
}

.rdt_TableCol:not(:last-child),
.rdt_TableCell:not(:last-child) {
  border-right: 2px solid #DEE2E6;
}

html .rdt_TableCell > div:first-child {
  white-space: normal;
}

.edit-columns-container > hr {
  border-top: 3px solid #DEE2E6;
}

html .form-control:focus,
html .form-select:focus {
  box-shadow: 0 0 0 0.1rem #E36F1E;
}

.react-select > .css-t3ipsp-control {
  box-shadow: 0 0 0 0.1rem #E36F1E;
  border: none;
}

.react-tooltip {
  z-index: 1;
}

.modal-scrollable .modal-dialog {
	max-height: 90vh;
	margin: 1rem auto;
}

.modal-scrollable .modal-content {
	max-height: 90vh;
	display: flex;
	flex-direction: column;
}

.modal-scrollable .modal-body {
	overflow-y: auto;
	flex: 1 1 auto;
	padding: 1rem;
}

.modal-scrollable .modal-header,
.modal-scrollable .modal-footer {
	flex: 0 0 auto;
}

.modal-dialog.modal-xxl {
  max-width: 95%;
}