.media-expense-screen .clickable {
    color: black;
    cursor: pointer;
    text-decoration: underline;
}

.media-expense-screen .select-search-input {
    height: 57px;
    border-radius: 0.35rem;
}

.media-expense-screen .condensed-button {
    padding: 0;
}

.media-expense-screen .react-select > div {
    padding: .64rem;
    color: black;
}

.edit-columns-row > div::after {
    content: '';
    position: absolute;
    right: 16%;
    top: 20%;
    bottom: 20%;
    width: 1px;
    background-color: black;
}

.edit-columns-row > div:last-child::after {
    background-color: transparent;
}

.edit-columns-row {
    margin-bottom: 3rem;
}

html .css-1nmdiq5-menu, /* Fix overlay not covering checkboxes in edit-columns-row */
html .react-select-3-listbox {
    z-index: 2;
}

.edit-column-months > div.container {
    padding-left: 1rem;
}

.edit-columns-row .custom-checkbox {
    padding-top: 10px;
}

.campaign-group-cell > div {
    margin-top: 10px;
}

.campaign-group-cell > div:last-child {
    margin-bottom: 10px;
}