html > body .payment-type-assignments-screen div { /* Fix font size in react data table. */
    font-size: 1rem;
}

.payment-type-assignments-screen .clickable {
    text-decoration: underline;
    cursor: pointer;
}

.add-new-assignment {
    margin-bottom: 30px;
}

button.close > span {
    font-size: 2rem;
}