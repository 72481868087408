.authoritative-actuals-details-screen .react-select > div {
    padding: .64rem;
    color: black;
}

.authoritative-actuals-details-screen .btn-upload {
    padding: 1rem;
}

.authoritative-actuals-details-screen .date-control {
    padding: 1.85rem;
}