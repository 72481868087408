html > body .margins-screen div { /* Fix font size in react data table. */
    font-size: 1rem;
}

.margins-screen .clickable {
    text-decoration: underline;
    cursor: pointer;
}

.add-new-margin {
    margin-bottom: 30px;
}